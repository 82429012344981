.gnb {
    border-top: 1px solid #dfdfdf;
    box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 20%);
}
.gnb > .nav-bar {
    margin: 0 auto;
    width: 1200px;
    height: 53px;
    line-height: 53px;
    display: flex;
}
.gnb > .nav-bar > .nav-item {
    flex: 1;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    letter-spacing: -1.3px;
}
.gnb > .nav-bar > .nav-item:hover,
.gnb > .nav-bar > .nav-item.active {
    background: #1E413A;
    color: white;
}
.gnb > .dropdown-content {
    position: absolute;
    display: none;
    background-color: #fff;
    width: 100%;
    min-width: 1200px;
    height: 211px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: -1;
    opacity: 1;
    margin-top: -1px;
}
@keyframes dropdown {
    from {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
    }
}
.gnb > .nav-bar:hover + .dropdown-content,
.gnb > .dropdown-content:hover {
    display: flex;
    animation-name: dropdown;
    animation-duration: 500ms;
    animation-direction: normal;
}
.gnb > .dropdown-content > .submenu-area {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 10px 0 10px 0;
}
.gnb > .dropdown-content > .submenu-area > .submenu-group {
    flex: 1;
    height: 100%;
    border-right: 1px solid #D8E2DA;
}
.gnb > .dropdown-content > .submenu-area > .submenu-group:last-child {
    border-right: 0;
}
.gnb > .dropdown-content > .submenu-area > .submenu-group > .submenu-box {
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    letter-spacing: -1.3px;
    font-weight: bold;
    text-align: center;
}
.gnb > .dropdown-content > .submenu-area > .submenu-group > .submenu-box.on {
    color:#777777;
}
.gnb > .dropdown-content > .submenu-area > .submenu-group > .submenu-box:hover {
    cursor: pointer;
}
.gnb > .dropdown-content > .submenu-area > .submenu-group > .submenu-box.on:hover {
    color: #fff;
    background-color: #1E413A;
}
.gnb > .dropdown-content > .submenu-area > .submenu-group > .submenu-box.off {
    color:#d4d4d4;
}
.autofill:-webkit-autofill { -webkit-box-shadow: 0 0 0 25px #ffffff inset; }