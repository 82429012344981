@font-face {
    font-family: "fm-a";
    src: url(assets/fonts/NanumSquareNeo/NanumSquareNeo-aLt.ttf);
}
@font-face {
    font-family: "fm-b";
    src: url(assets/fonts/NanumSquareNeo/NanumSquareNeo-bRg.ttf);
}
@font-face {
    font-family: "fm-c";
    src: url(assets/fonts/NanumSquareNeo/NanumSquareNeo-cBd.ttf);
}
@font-face {
    font-family: "NanumSquareNeo-aLt";
    src: url(assets/fonts/NanumSquareNeo/NanumSquareNeo-aLt.ttf);
}
@font-face {
    font-family: "NanumSquareNeo-bRg";
    src: url(assets/fonts/NanumSquareNeo/NanumSquareNeo-bRg.ttf);
}
@font-face {
    font-family: "NanumSquareNeo-cBd";
    src: url(assets/fonts/NanumSquareNeo/NanumSquareNeo-cBd.ttf);
}

body {
    margin: 0;
    font-family: NanumSquareNeo-cBd;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 아래의 모든 코드는 영역::코드로 사용 */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    /* 스크롤바의 너비 */
}

::-webkit-scrollbar-thumb {
    height: 30%;
    /* 스크롤바의 길이 */
    background: #969696;
    /* 스크롤바의 색상 */

    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);
    /*스크롤바 뒷 배경 색상*/
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}