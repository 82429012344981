.bgcWhite-i { background-color: white !important; }
.bgcWhite { background-color: white; }
.bgcBlueviolet { background-color: blueviolet; }
.bgcRed { background-color: red; }
.bgcBlue { background-color: blue; }
.bgcYellow { background-color: yellow; }
.bgcBlack { background-color: black; }
.bgcFAFAFA { background-color: #fafafa; }
.bgcBtnClose { background-color: #878787; }
.bgcBtnConfirm { background-color: darkred; }
.bgcBtnCrimson { background-color: crimson; }
.bgcBtnSave { background-color: #33ABAB; }
.bgcForest { background-color: #33ABAB; }
.bgcA4A4A4 {background-color: #a4a4a4; }
.bgcOrange { background-color: orange; }
.bgcGreen { background-color: green; }
.bgc707070 { background-color: #707070; }
.bgcDEDEDE { background-color: #DEDEDE; }
.bgc96c832 { background-color: #96c832; }
.bgc007CE8 { background-color: #007CE8; }
.bgc804040 { background-color: #804040; }
.bgcB36666 { background-color: #B36666; }
.bgc007C01 { background-color: #007C01; }
.bgcFFE6CE { background-color: #ffe6ce; }
.bgcFCA852 { background-color: #FCA852; }
.bgc87C6FD { background-color: #87C6FD; }
.bgc2882B7 { background-color: #2882B7; }
.bgc505050 { background-color: #505050; }
.bgc1E413A { background-color: #1E413A; }

.bgcDim { background-color: rgba(0, 0, 0, 0.6); }

.fm-a { font-family: fm-a; }
.fm-b { font-family: fm-b; }
.fm-c { font-family: fm-c; }

.fcBlack { color: black; }
.fcBlack-i { color: black !important; }
.fcWhite { color: white; }
.fcCrimson { color: crimson; }
.fc96c832 {color: #96c832; }
.fc009696 {color: #009696;}
.fc333333 { color: #333; }
.fc505050 { color: #505050; }
.fc878787 { color: #878787; }
.fcBDBDBD { color: #BDBDBD; }
.fc2C2C2C { color: #2c2c2c; }
.fc247979 { color: #247979; }
.fc007CE8 { color: #007CE8; }
.fc212121 { color: #212121; }

.hAuto { height: auto; }
.h10p { height: 10%; }
.h20p { height: 20%; }
.h30p { height: 30%; }
.h40p { height: 40%; }
.h50p { height: 50%; }
.h60p { height: 60%; }
.h70p { height: 70%; }
.h80p { height: 80%; }
.h90p { height: 90%; }
.h100p { height: 100%; }
.h0 { height:0; }
.h4 { height:4px; }
.h10 { height:10px; }
.h13 { height:13px; }
.h15 { height:15px; }
.h17 { height:17px; }
.h18 { height:18px; }
.h20 { height:20px; }
.h25 { height:25px; }
.h30 { height:30px; }
.h32 { height:32px; }
.h35 { height:35px; }
.h36 { height:36px; }
.h37 { height:37px; }
.h39 { height:39px; }
.h40 { height:40px; }
.h45 { height:45px; }
.h42 { height:42px; }
.h45 { height:45px; }
.h48 { height:48px; }
.h50 { height:50px; }
.h53 { height: 53px; }
.h60 { height: 60px; }
.h70 { height: 70px; }
.h75 { height: 75px; }
.h80 { height: 80px; }
.h90 { height: 90px; }
.h95 { height: 95px; }
.h96 { height: 96px; }
.h100 { height: 100px; }
.h105 { height: 105px; }
.h120 { height: 120px; }
.h130 { height: 130px; }
.h149 { height: 149px; }
.h150 { height: 150px; }
.h161 { height: 161px; }
.h200 { height: 200px; }
.h226 { height: 226px; }
.h251 { height: 251px; }
.h270 { height: 270px; }
.h275 { height:275px; }
.h300 { height:300px; }
.h320 { height:320px; }
.h340 { height:340px; }
.h341 { height:341px; }
.h350 { height:350px; }
.h367 { height:367px; }
.h400 { height:400px; }
.h407 { height:407px; }
.h415 { height:415px; }
.h420 { height:420px; }
.h425 { height:425px; }
.h450 { height:450px; }
.h460 { height:460px; }
.h470 { height:470px; }
.h472 { height:472px; }
.h480 { height:480px; }
.h484 { height:484px; }
.h490 { height:490px; }
.h500 { height: 500px; }
.h510 { height: 510px; }
.h550 { height: 550px; }
.h572 { height: 572px; }
.h580 { height: 580px; }
.h600 { height: 600px; }
.h611 { height: 611px; }
.h650 { height: 650px; }
.h670 { height: 670px; }
.h680 { height: 680px; }
.h700 { height: 700px; }
.h712 { height: 712px; }

.h35-i { height:35px !important; }

.h20p { height:20% !important; }
.h80p { height:80% !important; }
.h100p { height:100%; }
.h100p-i { height:100% !important; }

.mh20 { min-height: 20px; }
.mh30 { min-height: 30px; }
.mh50 { min-height: 50px; }
.mh100 { min-height: 100px; }
.mh200 { min-height: 200px; }
.mh300 { min-height: 300px; }
.mh360 { min-height: 360px; }
.mh500 { min-height: 500px; }
.mh536 { min-height: 536px; }
.mh656 { min-height: 656px; }

.mxh100 { max-height: 100px; }
.mxh350 { max-height: 350px; }

.lh10 { line-height: 10px; }
.lh15 { line-height: 15px; }
.lh18 { line-height: 18px; }
.lh20 { line-height: 20px; }
.lh21 { line-height: 21px; }
.lh23 { line-height: 23px; }
.lh25 { line-height: 25px; }
.lh26 { line-height: 26px; }
.lh30 { line-height: 30px; }
.lh32 { line-height: 32px; }
.lh40 { line-height: 40px; }
.lh53 { line-height: 53px; }
.lh120 { line-height: 120px; }
.lh100p { line-height: 100%; }

.w3p { width: 3%; }
.w5p { width: 5%; }
.w8p { width: 8%; }
.w9p { width: 9%; }
.w10p { width: 10%; }
.w15p { width: 15%; }
.w20p { width: 20%; }
.w23p { width: 23%; }
.w25p { width: 25%; }
.w27p { width: 27%; }
.w30p { width: 30%; }
.w35p { width: 35%; }
.w40p { width: 40%; }
.w45p { width: 45%; }
.w48p { width: 48%; }
.w49p { width: 49%; }
.w50p { width: 50%; }
.w60p { width: 60%; }
.w65p { width: 65%; }
.w77p { width: 77%; }
.w80p { width: 80%; }
.w90p { width: 90%; }
.w100p { width: 100%; }

.w4 { width: 4px; }
.w15 { width: 15px; }
.w17 { width: 17px; }
.w20 { width: 20px; }
.w24 { width: 24px; }
.w30 { width: 30px; }
.w35 { width: 35px; }
.w39 { width: 39px; }
.w50 { width: 50px; }
.w55 { width: 55px; }
.w60 { width: 60px; }
.w65 { width: 65px; }
.w70 { width: 70px; }
.w75 { width: 75px; }
.w80 { width: 80px; }
.w85 { width: 85px; }
.w90 { width: 90px; }
.w95 { width: 95px; }
.w92 { width: 92px; }
.w86 { width: 86px; }
.w100 { width: 100px; }
.w105 { width: 105px; }
.w110 { width: 110px; }
.w115 { width: 115px; }
.w120 { width: 120px; }
.w125 { width: 125px; }
.w130 { width: 130px; }
.w135 { width: 135px; }
.w137 { width: 137px; }
.w140 { width: 140px; }
.w145 { width: 145px; }
.w150 { width: 150px; }
.w155 { width: 155px; }
.w160 { width: 160px; }
.w165 { width: 165px; }
.w170 { width: 170px; }
.w175 { width: 175px; }
.w180 { width: 180px; }
.w185 { width: 185px; }
.w200 { width: 200px; }
.w210 { width: 210px; }
.w220 { width: 220px; }
.w230 { width: 230px; }
.w240 { width: 240px; }
.w250 { width: 250px; }
.w260 { width: 260px; }
.w300 { width: 300px; }
.w350 { width: 350px; }
.w360 { width: 360px; }
.w380 { width: 380px; }
.w400 { width: 400px; }
.w420 { width: 420px; }
.w440 { width: 440px; }
.w460 { width: 460px; }
.w472 { width: 472px; }
.w500 { width: 500px; }
.w509 { width: 509px; }
.w550 { width: 550px; }
.w600 { width: 600px; }
.w650 { width: 650px; }
.w668 { width: 668px; }
.w672 { width: 672px; }
.w685 { width: 685px; }
.w700 { width: 700px; }
.w720 { width: 720px; }
.w725 { width: 725px; }
.w750 { width: 750px; }
.w800 { width: 800px; }
.w850 { width: 850px; }
.w870 { width: 870px; }
.w872 { width: 872px; }
.w900 { width: 900px; }
.w950 { width: 950px; }
.w1000 { width: 1000px; }
.w1100 { width: 1100px; }
.w1200 { width: 1200px; }
.w1300 { width: 1300px; }
.w1500 { width: 1500px; }

.w35-i { width: 35px !important; }

.mw70 { min-width: 70px;}
.mw100 { min-width: 100px;}
.mw120 { min-width: 120px;}
.mw200 { min-width: 200px;}
.mw250 { min-width: 250px;}
.mw1000 { min-width: 1000px;}
.mw1200 { min-width: 1200px;}

.mxw278 { max-width: 278px; }
.mxw460 { max-width: 460px; }

.m0 { margin:0; }
.m0auto { margin: 0 auto; }
.m5 { margin: 5px; }
.m10 { margin: 10px; }
.mh5 { margin: 5px 0; }

.mr5 { margin-right: 5px; }
.mr7 { margin-right: 7px; }
.mr8 { margin-right: 8px; }

.mt2 { margin-top: 2px; }
.mt3 { margin-top: 3px; }
.mt4 { margin-top: 4px; }
.mt5 { margin-top: 5px; }
.mt6 { margin-top: 6px; }
.mt7 { margin-top: 7px; }
.mt8 { margin-top: 8px; }
.mt9 { margin-top: 9px; }
.mt10 { margin-top: 10px; }
.mt14 { margin-top: 14px; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt50 { margin-top: 50px; }
.mt80 { margin-top: 80px; }
.mt85 { margin-top: 85px; }
.mt88 { margin-top: 88px; }
.mb0 { margin-bottom: 0; }
.mb2 { margin-bottom: 2px; }
.mb3 { margin-bottom: 3px; }
.mb5 { margin-bottom: 5px; }
.mb6 { margin-bottom: 6px; }
.mb7 { margin-bottom: 5px; }
.mb8 { margin-bottom: 8px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 20px; }
.mb25 { margin-bottom: 25px; }
.mb30 { margin-bottom: 30px; }
.mb35 { margin-bottom: 35px; }
.mb40 { margin-bottom: 40px; }
.mb50 { margin-bottom: 50px; }
.mb60 { margin-bottom: 60px; }
.mb80 { margin-bottom: 80px; }
.mb100 { margin-bottom: 100px; }
.mb110 { margin-bottom: 110px; }
.mb130 { margin-bottom: 130px; }
.ml0-i { margin-left: 0 !important; }
.ml5 { margin-left: 5px; }
.ml5-i { margin-left: 5px !important; }
.ml8 { margin-left: 8px; }
.ml10 { margin-left: 10px; }
.ml20 { margin-left: 20px; }
.ml30 { margin-left: 30px; }
.ml50 { margin-left: 50px; }
.mr3 { margin-right: 3px; }
.mr5 { margin-right: 5px; }
.mr10 { margin-right: 10px; }
.mr15 { margin-right: 15px; }
.mr20 { margin-right: 20px; }
.mr30 { margin-right: 30px; }
.mr30-i { margin-right: 30px !important; }

.p0 { padding: 0; }
.p5 { padding: 5px; }
.p10 { padding: 10px; }
.p10-i { padding: 10px !important; }
.p15 { padding: 15px; }
.p20 { padding: 20px; }
.p30 { padding: 30px; }
.p40 { padding: 40px; }

.prl13 { padding: 0 13px; }
.pw5 { padding: 0 5px; }
.pw10 { padding: 0 10px; }
.ph10 { padding: 10px 0; }

.pt5 { padding-top: 5px; }
.pt10 { padding-top: 10px; }
.pt15 { padding-top: 15px; }
.pt20 { padding-top: 20px; }
.pt24 { padding-top: 24px; }
.pt25 { padding-top: 25px; }
.pt30 { padding-top: 30px; }
.pt40 { padding-top: 40px; }
.pt50 { padding-top: 50px; }
.pt75 { padding-top: 75px; }

.pr3 { padding-right: 3px; }
.pr5 { padding-right: 5px; }
.pr10 { padding-right: 10px; }
.pr13 { padding-right: 13px; }
.pr14 { padding-right: 14px; }
.pr15 { padding-right: 15px; }
.pr20 { padding-right: 20px; }
.pr30 { padding-right: 30px; }
.pr50 { padding-right: 50px; }
.pr80 { padding-right: 80px; }
.pr100 { padding-right: 100px; }

.pb4 { padding-bottom: 4px; }
.pb5 { padding-bottom: 5px; }
.pb10 { padding-bottom: 10px; }
.pb15 { padding-bottom: 15px; }
.pb20 { padding-bottom: 20px; }
.pb30 { padding-bottom: 30px; }
.pb35 { padding-bottom: 35px; }
.pb40 { padding-bottom: 40px; }
.pb50 { padding-bottom: 50px; }

.pl0 { padding-left: 0px; }
.pl2 { padding-left: 2px; }
.pl3 { padding-left: 3px; }
.pl5 { padding-left: 5px; }
.pl7 { padding-left: 7px; }
.pl10 { padding-left: 10px; }
.pl13 { padding-left: 13px; }
.pl14 { padding-left: 14px; }
.pl15 { padding-left: 15px; }
.pl20 { padding-left: 20px; }
.pl30 { padding-left: 30px; }
.pl50 { padding-left: 50px; }
.pl80 { padding-left: 80px; }

.bdN { border: none; }
.bd0 { border: 0; }
.bd1 { border: 1px solid; }
.bd1-i { border: 1px solid !important; }

.bdDot1 { border: 1px dotted; }
.bdDash1 { border: 1px dashed; }

.bdt1 { border-top: 1px solid; }
.bdb1 { border-bottom: 1px solid; }
.bdb2 { border-bottom: 2px solid; }
.bdb4 { border-bottom: 4px solid; }
.bdb5 { border-bottom: 5px solid; }
.bdl1 { border-left: 1px solid; }

.bdrd50p { border-radius: 50%;}
.bdrd0 { border-radius: 0px;}
.bdrd3 { border-radius: 3px;}
.bdrd4 { border-radius: 4px;}
.bdrd5 { border-radius: 5px;}

.bdrdtl3 { border-top-left-radius: 3px; }
.bdrdtr3 { border-top-right-radius: 3px; }

.bdcBlack { border-color: black; }
.bdcBlack-i { border-color: black !important; }
.bdcWhite { border-color: white; }
.bdcCECECE { border-color: #CECECE; }
.bdcDFDFDF { border-color: #DFDFDF; }
.bdc878787 { border-color: #878787; }
.bdc505050 { border-color: #505050; }
.bdcForest { border-color: #009696; }
.bdcOrange { border-color: orange; }
.bdcGreen { border-color: green; }
.bdc007CE8 { border-color: #007CE8; }
.bdc804040 { border-color: #804040; }
.bdcB36666 { border-color: #B36666; }
.bdcFCA852 { border-color: #FCA852; }
.bdc2882B7 { border-color: #2882B7; }
.bdc007C01 { border-color: #007C01; }
.bdcDDDDDD { border-color: #DDDDDD; }

.dpNone { display: none; }
.dpNone-i { display: none !important; }
.dpBlock { display: block; }
.dpInBlock { display: inline-block; }
.dpFlx { display: flex; }
.dpGrid { display: grid; }

.float-r { float: right; }
.float-l { float: left; }

.flx1 { flex: 1 }

.fs10-i { font-size: 10px !important; }
.fs11-i { font-size: 11px !important; }
.fs12-i { font-size: 12px !important; }
.fs13-i { font-size: 13px !important; }
.fs14-i { font-size: 14px !important; }
.fs15-i { font-size: 15px !important; }
.fs16-i { font-size: 16px !important; }
.fs17-i { font-size: 17px !important; }
.fs18-i { font-size: 18px !important; }
.fs19-i { font-size: 19px !important; }

.fs10 { font-size: 10px; }
.fs12 { font-size: 12px; }
.fs13 { font-size: 13px; }
.fs14 { font-size: 14px; }
.fs15 { font-size: 15px; }
.fs16 { font-size: 16px; }
.fs17 { font-size: 17px; }
.fs18 { font-size: 18px; }
.fs20 { font-size: 20px; }
.fs22 { font-size: 22px; }
.fs23 { font-size: 23px; }
.fs25 { font-size: 25px; }
.fs35 { font-size: 35px; }
.fs36 { font-size: 36px; }
.fs37 { font-size: 37px; }
.fs39 { font-size: 39px; }
.fs40 { font-size: 40px; }
.fs50 { font-size: 50px; }

.ls2 { letter-spacing: -2px; }
.ls1 { letter-spacing: -1px; }
.ls09 { letter-spacing: -0.9px; }
.ls08 { letter-spacing: -0.8px; }
.ls07 { letter-spacing: -0.7px; }
.ls06 { letter-spacing: -0.6px; }
.ls13 { letter-spacing: -1.3px; }
.ls12 { letter-spacing: -1.2px; }
.ls11 { letter-spacing: -1.1px; }

.fwB { font-weight: bold; }

.NanumSquareNeo-cBd {
    font-family: NanumSquareNeo-cBd;
}
.NanumSquareNeo-bRg {
    font-family: NanumSquareNeo-bRg;
}

.aiC { align-items: center; }
.aiR { align-items: right; }

.jcC { justify-content: center; }
.jcL { justify-content: left; }
.jcR { justify-content: right; }
.jcSb { justify-content: space-between; }

.taC { text-align: center; }
.taL { text-align: left; }
.taR { text-align: right; }

.tdN { text-decoration: none !important;}
.tdU { text-decoration: underline; }

.tuOs2 { text-underline-offset: 2px; }
.tuOs10 { text-underline-offset: 10px; }

.csP { cursor: pointer; }

.zIdx0 { z-index: 0; }
.zIdx1 { z-index: 1; }
.zIdx1000 { z-index: 1000; }
.zIdx-1 { z-index: -1; }
.zIdx-2 { z-index: -2; }

.posR { position: relative; }
.posA { position: absolute; }
.posF { position: fixed; }

.t0 { top:0 }
.r0 { right:0 }
.l0 { left:0 }
.b0 { bottom:0 }

.vtaM { vertical-align: middle; }

.ofH { overflow: hidden; }
.ofA { overflow: auto; }
.ofxA { overflow-x: auto; }

.usN { user-select: none; }

.lsN { list-style: none; }

.ti-7 { text-indent: -7px; }

.wbKA { word-break: keep-all;}
.wbBA { word-break: break-all;}
.wbN { word-break: normal;}

.borderBox { box-sizing: border-box; }
.bdBox { box-sizing: border-box; }

.flxdCol { flex-direction: column; }

.focusBorder:focus, .focusBorder:hover {
	border-color: #96c832;
	outline: none;
}
.olN {
    outline: none
}
.acGrey { accent-color: grey; }
.acForest { accent-color: #009696; }

.wsPw { white-space: pre-wrap; }

.rszN {resize: none;}

.op0{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter:alpha(opacity=0);-moz-opacity:0;-khtml-opacity:0;opacity:0 !important;}
.op10{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";filter:alpha(opacity=10);-moz-opacity:0.1;-khtml-opacity:0.1;opacity:0.1 !important;}
.op20{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";filter:alpha(opacity=20);-moz-opacity:0.2;-khtml-opacity:0.2;opacity:0.2 !important;}
.op30{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";filter:alpha(opacity=30);-moz-opacity:0.3;-khtml-opacity:0.3;opacity:0.3 !important;}
.op40{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";filter:alpha(opacity=40);-moz-opacity:0.4;-khtml-opacity:0.4;opacity:0.4 !important;}
.op50{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";filter:alpha(opacity=50);-moz-opacity:0.5;-khtml-opacity:0.5;opacity:0.5 !important;}
.op60{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";filter:alpha(opacity=60);-moz-opacity:0.6;-khtml-opacity:0.6;opacity:0.6 !important;}
.op70{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";filter:alpha(opacity=70);-moz-opacity:0.7;-khtml-opacity:0.7;opacity:0.7 !important;}
.op80{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";filter:alpha(opacity=80);-moz-opacity:0.8;-khtml-opacity:0.8;opacity:0.8 !important;}
.op90{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";filter:alpha(opacity=90);-moz-opacity:0.9;-khtml-opacity:0.9;opacity:0.9 !important;}
.op100{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";filter:alpha(opacity=100);-moz-opacity:1;-khtml-opacity:1;opacity:1 !important;}

.op10-hover:hover{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";filter:alpha(opacity=10);-moz-opacity:0.1;-khtml-opacity:0.1;opacity:0.1 !important; cursor:pointer !important;}
.op20-hover:hover{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";filter:alpha(opacity=20);-moz-opacity:0.2;-khtml-opacity:0.2;opacity:0.2 !important; cursor:pointer !important;}
.op30-hover:hover{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";filter:alpha(opacity=30);-moz-opacity:0.3;-khtml-opacity:0.3;opacity:0.3 !important; cursor:pointer !important;}
.op40-hover:hover{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";filter:alpha(opacity=40);-moz-opacity:0.4;-khtml-opacity:0.4;opacity:0.4 !important; cursor:pointer !important;}
.op50-hover:hover{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";filter:alpha(opacity=50);-moz-opacity:0.5;-khtml-opacity:0.5;opacity:0.5 !important; cursor:pointer !important;}
.op60-hover:hover{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";filter:alpha(opacity=60);-moz-opacity:0.6;-khtml-opacity:0.6;opacity:0.6 !important; cursor:pointer !important;}
.op70-hover:hover{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";filter:alpha(opacity=70);-moz-opacity:0.7;-khtml-opacity:0.7;opacity:0.7 !important; cursor:pointer !important;}
.op80-hover:hover{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";filter:alpha(opacity=80);-moz-opacity:0.8;-khtml-opacity:0.8;opacity:0.8 !important; cursor:pointer !important;}
.op90-hover:hover{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";filter:alpha(opacity=90);-moz-opacity:0.9;-khtml-opacity:0.9;opacity:0.9 !important; cursor:pointer !important;}