ul.react-paginate {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
}
ul.react-paginate li a {
    width: 35px;
    height: 35px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 13px;
}
ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
    border-color: transparent;
}
ul.react-paginate li.selected a {
    border: 1px solid #87C800;
    background: #E5F8E5;
}
ul.react-paginate li.selected a {
    color: #476800;
    font-weight: bold;
}

ul.react-paginate li.disabled a {
    color: grey;
}

ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
    cursor: default;
}

.page-chg-btn {
    width: 45px;
    height: 27px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-top: 1px solid #7a7979;
    border-bottom: 1px solid #7a7979;
    border-left: 1px solid #7a7979;
}
.page-chg-btn:first-child {
    border-radius: 5px 0 0 5px;
}
.page-chg-btn:last-child {
    border-right: 1px solid #7a7979;
    border-radius: 0 5px 5px 0;
}
.page-chg-btn.active {
    background-color: #E5F8E5;
}
.page-chg-btn.pg-cnt {
    color: #333333;
}
.page-chg-btn.active .pg-cnt {
    color: #476800;
    background-color: #E5F8E5;
    font-weight: bold;
}
.buttons { display: flex; }
.buttons .btn input[type="radio"],
.buttons .page-chg-btn input[type="radio"],
.buttons .btn-group > .btn input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}