.form-table {
    position: relative;
    width: 100%;
    border-top: 1px solid #dbdbdb;
    border-spacing: 0;
    font-size: 0;
}

.form-table.tr-h30 > tbody > tr { height: 30px; }
.form-table.tr-h35 > tbody > tr { height: 35px; }
.form-table.tr-h43 > tbody > tr { height: 43px; }

.form-table > tbody > tr > th {
    background-color: #F8F8F8;
    font-size: 13px;
    letter-spacing: -1.1px;
    color: #333333;
    padding: 0 12px 0 12px;
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
}

.form-table > tbody > tr >  th:last-child {
    border-left: 1px solid #DFDFDF;
}

.form-table > tbody > tr >  th.taL {
    text-align: left;
}

.form-table > tbody > tr >  th.taC {
    text-align: center;
}

.form-table > tbody > tr >  th.taR {
    text-align: right;
}

.field-comment {
    display: flex;
    font-weight: bold;
    float: left;
    line-height: 13px;
}

.not-null {
    color: crimson !important;
    display: inline-block;
    padding-left: 3px;
}

.edit-psb {
    width: 10px;
    height: 10px;
    background-color: antiquewhite;
    border: 1px solid darkgoldenrod;
    display: inline-block;
}

.form-table > tbody > tr > td {
    border-color: #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    padding: 0 5px;
}
.form-table.td-p-row > tbody > tr > td {
    padding: 0 10px;
}
.form-table.td-ls06 > tbody > tr > td {
    letter-spacing: -0.6px;
}
.form-table.td-fs12 > tbody > tr > td { font-size: 12px; }
.form-table.td-fs13 > tbody > tr > td { font-size: 13px; }
.form-table.td-fs14 > tbody > tr > td { font-size: 14px; }
.form-table.td-fs15 > tbody > tr > td { font-size: 15px; }

.form-table.td-ls06 > tbody > tr > td { letter-spacing: -0.6px; }

.form-table.td-fc222222 > tbody > tr > td { color: #222222; }

.form-table > tbody > tr > td.center {
    text-align: center;
}

.form-table > tbody > tr > td.first-child {
    border-left: 1px solid #dbdbdb;
}

.form-table > tbody > tr > td.col-sum {
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
}
.form-table > tbody > tr > td.br1 {
    border-right: 1px solid #dbdbdb;
}

.form-table > tbody > tr > td.bgcF8F8F8 {
    background-color: #F8F8F8;
}

/* 필드 2개인 테이블 */
.form-table.col2 > tbody > tr > th {
    width: 20%;
}

.form-table.col2 > tbody > tr > td {
    width: 80%;
}

.form-table.col3 > tbody > tr > th {
    width: 10%;
}

.form-table.col3 > tbody > tr > td {
    width: 80%;
}

/* 필드 4개인 테이블 */
.form-table.col4 > tbody > tr > th {
    width: 20%;
}

.form-table.col4 > tbody > tr > td {
    width: 30%;
}

/* 필드 4개인 테이블 : th/td 동일비율 */
.form-table.col4-2 > tbody > tr > th,
.form-table.col4-2 > tbody > tr > td {
    width: 25%;
}

/* 필드 4개인 테이블 : 21 / 29 */
.form-table.col4-3 > tbody > tr > th {
    width: 21%;
}

.form-table.col4-3 > tbody > tr > td {
    width: 29%;
}

/* 필드 4개인 테이블 : 22 / 28 */
.form-table.col4-4 > tbody > tr > th {
    width: 22%;
}

.form-table.col4-4 > tbody > tr > td {
    width: 28%;
}

/* 필드 6개인 테이블 */
.form-table.col6 > tbody > tr > th {
    width: 13.3%;
}

.form-table.col6 > tbody > tr > td {
    width: 20%;
}

/* 필드 6개인 테이블 : th/td 동일비율 */
.form-table.col6-2 > tbody > tr > th,
.form-table.col6-2 > tbody > tr > td {
    width: 16.66%;
}

/** 필드 6개인 테이블 th 조금 큰 경우*/
.form-table.col6-3 > tbody > tr > th {
    width: 14%;
}

.form-table.col6-3 > tbody > tr > td {
    width: 19.33%;
}

.list-table {
    position: relative;
    border-spacing: 0;
    font-size: 0;
}
.list-table.tr-h30 > tbody > tr { height: 30px; }
.list-table.tr-h36 > tbody > tr { height: 36px; }
.list-table.tr-h44 > tbody > tr { height: 44px; }

.list-table.th-bdr1 > tbody > tr > th { border-right: 1px solid; }
.list-table.th-bdr1 > tbody > tr > th:last-child { border-right: none; }

.list-table.th-bdb1 > tbody > tr > th { border-bottom: 1px solid; }
.list-table.th-bdb1 > tbody > tr:last-child > th { border-bottom: none; }

.list-table.th-bdcDDDDDD > tbody > tr > th { border-color: #DDDDDD; }

.list-table.th-fm-b > tbody > tr > th { font-family: fm-b; }

.list-table.th-fs14 > tbody > tr > th { font-size: 14px; }

.list-table.th-fc222222 > tbody > tr > th { color: #222222; }
.list-table.th-fc2B2B2B > tbody > tr > th { color: #2B2B2B; }

.list-table.th-ls07 > tbody > tr > th { letter-spacing: -0.7px; }
.list-table.th-ls1 > tbody > tr > th { letter-spacing: -1px; }

.list-table.th-fw500 > tbody > tr > th { font-weight: 500; }

.list-table > tbody > tr > td {
    font-size: 15px;
    border-right: 1px solid;
    border-bottom: 1px solid;
}
.list-table > tbody > tr:last-child > td {
    border-bottom: none;
}
.list-table > tbody > tr > td:last-child {
    border-right: none;
}

.list-table.td-p20 > tbody > tr > td { padding: 0 20px 0 20px; }

.list-table.td-bdcDDDDDD > tbody > tr > td {
    border-color: #DDDDDD;
}

.list-table.td-fm-a > tbody > tr > td { font-family: fm-a; }
.list-table.td-fm-b > tbody > tr > td { font-family: fm-b; }
.list-table.td-fm-c > tbody > tr > td { font-family: fm-c; }


.list-table.td-ls075 > tbody > tr > td { letter-spacing: -0.75px; }
.list-table.td-ls1 > tbody > tr > td { letter-spacing: -1px; }

.list-table.td-fc666666 > tbody > tr > td { color: #666666; }

.list-table.td-fs14 > tbody > tr > td { font-size: 14px; }
.list-table.td-fs15 > tbody > tr > td { font-size: 15px; }

.list-table.td-lh20 > tbody > tr > td { line-height: 20px; }

.list-table.td-p5 > tbody > tr > td { padding: 0 5px 0 5px; }
.list-table.td-p10 > tbody > tr > td { padding: 10px; }
.list-table.td-p15 > tbody > tr > td { padding: 0 15px 0 15px; }
.list-table.td-p30 > tbody > tr > td { padding: 0 30px 0 30px; }

.list-table.fs12 > tbody > tr > td {
    font-size: 12px;
    letter-spacing: -0.6px;
}
.list-table.fs11 > tbody > tr > td {
    font-size: 11px;
    letter-spacing: -0.6px;
}
.list-table.h40 > tbody > tr {
    height: 40px;
}
.list-table.h43 > tbody > tr {
    height: 43px;
}
.list-table.h48 > tbody > tr {
    height: 48px;
}
.list-table.h26 > tbody > tr {
    height: 26px;
}
.list-table.h26 > tbody > tr > th {
    height: 35px;
}
.list-table.h25 > tbody > tr {
    height: 25px;
}
.list-table > tbody > tr > th {
    overflow: hidden;
    font-size: 14px;
    font-family: SpoqaHanSansNeo-Medium;
    letter-spacing: -0.7px;
    color: #2b2b2b;
    padding: 0 12px 0 12px;
    background-color: #f0f0f0;
}
.list-table > tbody > tr > th.bdt1 {
    border-top: 1px solid #dbdbdb;
}
.list-table > tbody > tr > th.bdr1 {
    border-right: 1px solid #dbdbdb;
}
.list-table > tbody > tr > th.bdb1 {
    border-bottom: 1px solid #dbdbdb;
}
.list-table > tbody > tr > th.bdt0 {
    border-top: 0;
}
.list-table > tbody > tr > th.bdr0 {
    border-right: 0;
}
.list-table > tbody > tr > th.bdb0 {
    border-bottom: 0;
}
.list-table > tbody > tr > td.bdt1 {
    border-top: 1px solid #dbdbdb;
}
.list-table > tbody > tr > td.bdr1 {
    border-right: 1px solid #dbdbdb;
}
.list-table > tbody > tr > td.bdb1 {
    border-bottom: 1px solid #dbdbdb;
}
.list-table > tbody > tr > td.bdl1 {
    border-left: 1px solid #dbdbdb;
}
.list-table > tbody > tr > td {
    font-family: SpoqaHanSansNeo-Regular;
    font-size: 13px;
    letter-spacing: -1.1px;
    color: #333333;
    text-align: center;
}
.list-table > tbody > tr > td.taL {
    text-align: left;
}
.list-table > tbody > tr > td.taR {
    text-align: right;
}
.list-table > tbody > tr > td.taC {
    text-align: center;
}